import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const footer = (
  <p>
    <h4>NO INVESTMENT ADVICE</h4>
    <small>
    The Content is for informational purposes only, you should not construe any such information or
    other material as legal, tax, investment, financial, or other advice. Nothing contained on this
    Site constitutes a solicitation, recommendation, endorsement, or offer by me or any third party
    service provider to buy or sell any securities or other financial instruments in this or in any
    other jurisdiction in which such solicitation or offer would be unlawful under the securities
    laws of such jurisdiction.
    <br /><br />
    Nothing in the Site constitutes professional and/or financial advice, nor does any information
    on the Site constitute a comprehensive or complete statement of the matters discussed or the
    law relating thereto. I am not a fiduciary by virtue of any person’s use of or access to the
    Site or Content. You alone assume the sole responsibility of evaluating the merits and risks
    associated with the use of any information or other Content on the Site before making any
    decisions based on such information or other Content. In exchange for using the Site, you agree
    not to hold me liable for any possible claim for damages arising from any decision you make
    based on information or other Content made available to you through the Site.
    </small>
  </p>
);

const financialPage = () => (
  <Layout title='Financial' footer={footer}>
    <p>
      The Financial section of this blog serves to educate myself and others on the state of our economy and financial systems. Topics of reflection range from price pressures affecting the U.S. equity market to the plumbing of our globalized economy.
      <br /><br />      
      While reading these articles, keep in mind that I am <i>not</i> an economist. And I am certainly not your financial advisor. Largely, these pieces are a synthesis of others' perspectives, sprinkled with a few of my own. I cite reliable sources where I can, but <a href='https://lmgtfy.com/?q=what+are+stonks%3F'>Google</a> is your friend when I do not.
      <br /><br />
      I put significant time and research into topics posted here. But please do your own before taking the word of this internet stranger to heart. Again, I am not an expert. Refer to the disclosure at the bottom of this page if you have doubts as to whether <i>any</i> of this constitutes financial advice.
      <br /><br />      
      I'll do my best to describe financial concepts in a language that's friendly to those outside this area of interest.
    </p>

    <br/><br/>
    <b><i>Published</i></b>
    <ul>
      <li><a href='/financial/hope-phase-2020'>2020 Stock Market in Review: The Hope Phase</a></li>
    </ul>

    <b><i>In the Works</i></b>
    <ul>
      <li><Link href='/financial/'>The Not So Roaring '20s (drafting)</Link></li>
      <li><Link href='/financial/'>Evaluating Small Business Behind Closed Doors (outlining)</Link></li>
      <li><Link href='/financial/'>Empires Don't Fall Overnight (outlining)</Link></li>
    </ul>
    

    <br /><br />
    <br /><br />

    
  </Layout>
)

export default financialPage

